import React, { ReactNode } from "react";
import {
  PermissionsFragment,
  RwdPermissions,
  RwdPermissionsFragment,
  RwdPermissionsInput,
  PermissionsInput as PermissionsInputFragment,
} from "../../../lib/graphql";
import {
  Row,
  Col,
  Tag,
  Checkbox,
  Divider,
  List,
  Typography,
  Collapse,
} from "antd";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { KeysOfType } from "../../../lib/types";
import { Form } from "../../form";
import { NamePath } from "antd/lib/form/interface";
import { groupBy, isEqual } from "lodash";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCurrentUser } from "../../../lib/hooks";
import { shouldUpdate } from "../../../lib/formats";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Space } from "../../shared";
import { modules, pages } from "../../../lib/modules";

export type RWDPermissionKeys = KeysOfType<
  Omit<PermissionsInputFragment, "changeLog">,
  RwdPermissionsInput | null | undefined
>;

const RWDPermissions = ["read", "write", "delete"] as Array<
  Exclude<keyof RwdPermissions, "__typename">
>;

const onRWDChange = (e: CheckboxChangeEvent) => {
  const value = e.target.value as CheckboxValueType;

  if (value === "read") {
    return {
      read: e.target.checked,
    };
  } else if (value === "write") {
    return {
      write: e.target.checked,
      read: true,
    };
  } else if (value === "delete") {
    return {
      delete: e.target.checked,
      write: true,
      read: true,
    };
  }
};

const RWDPermissionNames: Record<string, any> = {
  workOrder: { delete: "revert" },
  financeOrder: { delete: "revert" },
  purchase: { delete: "revert" },
  sale: { delete: "revert" },
  transfer: { delete: "revert" },
  journalEntry: { delete: "revert" },
  financeInvoice: { delete: "revert" },
};

const RWDPermissionInput = ({
  name,
  title,
  disabled,
}: {
  name: string[];
  title: ReactNode;
  disabled?: boolean;
}) => (
  <Row justify="space-between">
    <Col span={10}>
      {/* fake check all checkbox */}
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          return !isEqual(
            prevValues.permissions?.[name[name.length - 1]],
            currentValues.permissions?.[name[name.length - 1]]
          );
        }}
      >
        {({ getFieldValue, setFields }) => {
          const permissionsByKey =
            getFieldValue("permissions")?.[name[name.length - 1]] || {};

          const permissionValues = Object.values(permissionsByKey).filter(
            (v) => v !== "RWDPermissions"
          );

          const checked =
            permissionValues.length === RWDPermissions.length &&
            permissionValues.every(Boolean);

          const setCheckAll = (e: CheckboxChangeEvent) => {
            setFields(
              RWDPermissions.map((o) => ({
                name: name.concat(o),
                value: e.target.checked,
              }))
            );
          };

          return (
            <Checkbox
              indeterminate={!checked && permissionValues.some(Boolean)}
              onChange={setCheckAll}
              checked={checked}
              disabled={disabled}
            >
              <Typography.Text strong>{title}</Typography.Text>
            </Checkbox>
          );
        }}
      </Form.Item>
    </Col>

    <Col span={14}>
      <Form.Item noStyle shouldUpdate={shouldUpdate(name)}>
        {({ getFieldValue, setFieldValue }) => {
          const value = getFieldValue(name);

          const onChange = (e: CheckboxChangeEvent) => {
            setFieldValue(name, onRWDChange(e));
          };

          const names = RWDPermissionNames[name[name.length - 1]] || {};

          return RWDPermissions.map((o) => (
            <Checkbox
              value={o}
              key={name.concat(o).join("-")}
              onChange={onChange}
              disabled={disabled}
              checked={value?.[o] || false}
            >
              <FormattedMessage
                {...{
                  id: names[o] || `permissions.${o}`,
                }}
              />
            </Checkbox>
          ));
        }}
      </Form.Item>
    </Col>
  </Row>
);

export const ReportKeys = [
  { key: "stock", title: { id: "stock", defaultMessage: "stock" } },
  {
    key: "journal",
    title: { id: "journalReport", defaultMessage: "journalReport" },
  },
  {
    key: "inventoryMovement",
    title: { id: "inventoryMovements", defaultMessage: "inventoryMovements" },
  },
  {
    key: "ledger",
    title: { id: "generalLedger", defaultMessage: "generalLedger" },
  },
  {
    key: "activity",
    title: { id: "activities", defaultMessage: "activities" },
  },
  {
    key: "labor",
    title: { id: "laborReport.pageTitle", defaultMessage: "Labor Report" },
  },
  {
    key: "cropField",
    title: { id: "cropFields", defaultMessage: "cropFields" },
  },
  {
    key: "trialBalance",
    title: { id: "trialBalance", defaultMessage: "trialBalance" },
  },
  {
    key: "balanceSheet",
    title: { id: "balanceSheet", defaultMessage: "Balance Sheet" },
  },
  {
    key: "token",
    title: { id: "tokenReport.pageTitle", defaultMessage: "tokenReport" },
  },
  {
    key: "harvest",
    title: { id: "reports.harvest.pageTitle", defaultMessage: "harvestReport" },
  },
  {
    key: "payroll",
    title: {
      id: "payrollReport.pageTitle",
      defaultMessage: "Payroll Report",
    },
  },
  {
    key: "workOrder",
    title: {
      id: "workOrderDailyReport.pageTitle",
      defaultMessage: "Work Orders Daily Report",
    },
  },
  {
    key: "expense",
    title: {
      id: "expenseReport.pageTitle",
      defaultMessage: "Expense Report",
    },
  },
  {
    key: "inputCostCenter",
    title: {
      id: "inputCostCenterReport.pageTitle",
      defaultMessage: "Input per Cost Center Report",
    },
  },
  {
    key: "incomeStatement",
    title: {
      id: "incomeStatementReport.pageTitle",
      defaultMessage: "Income Statement",
    },
  },
] as const;

export const AdministrativeKeys = [
  {
    key: "company",
    title: { id: "companies.settings", defaultMessage: "settings" },
  },
  {
    key: "import",
    title: { id: "imports", defaultMessage: "imports" },
  },
  {
    key: "editPlanning",
    title: { id: "permissions.editPlanning" },
  },
  {
    key: "showCost",
    title: { id: "permissions.showCost" },
  },
  {
    key: "showWage",
    title: { id: "permissions.showWage" },
  },
  {
    key: "testMode",
    title: { id: "tenants.testMode" },
  },
  {
    key: "resetTestMode",
    title: { id: "tenants.testMode.reset" },
  },
  {
    key: "resetPassword",
    title: { id: "users.generateResetPassword.title" },
  },
  {
    key: "billing",
    title: { id: "billing", defaultMessage: "Billing" },
  },
] as const;

export const FarmMapKeys = [
  {
    key: "cropStages",
    title: { id: "localities.map.cropStages", defaultMessage: "Crop Stages" },
  },
  {
    key: "yields",
    title: {
      id: "localities.map.harvestYield",
      defaultMessage: "Harvest Yield",
    },
  },
  {
    key: "costs",
    title: { id: "costs", defaultMessage: "Costs" },
  },
  {
    key: "activities",
    title: { id: "activities", defaultMessage: "Activities" },
  },
];

const GenericPermissionsInput = ({
  items,
  disabled,
}: {
  items: { key: NamePath; title: MessageDescriptor }[];
  disabled?: boolean;
}) => (
  <List
    size="small"
    split={false}
    style={{ marginTop: "-7px" }}
    dataSource={items}
    renderItem={({ key, title }) => (
      <List.Item style={{ paddingLeft: 0 }}>
        <Form.Item noStyle name={key} valuePropName="checked">
          <Checkbox disabled={disabled}>
            <FormattedMessage {...title} />
          </Checkbox>
        </Form.Item>
      </List.Item>
    )}
  />
);

type FeaturePermissions<T> = {
  feature: boolean | undefined | null;
  permissions: Array<T | undefined>;
}[];

export function useRwdInputs({
  includeSensitive,
}: {
  includeSensitive?: boolean;
} = {}) {
  const intl = useIntl();
  const { currentTenant, user } = useCurrentUser();

  const featurePermissions: FeaturePermissions<keyof PermissionsInputFragment> =
    [
      { feature: currentTenant.features.planning, permissions: ["planning"] },
      {
        feature: currentTenant.features.payroll,
        permissions: ["salaryStructure", "payrollEntry"],
      },
      {
        feature: currentTenant.features.accounting,
        permissions: ["account", "journalEntry"],
      },
      {
        feature: currentTenant.features.inventoryStock,
        permissions: [
          "transfer",
          "adjustmentType",
          "purchase",
          "inventoryRequest",
          "sale",
        ],
      },
      {
        feature: currentTenant.features.costCenters,
        permissions: ["costCenter"],
      },
      {
        feature: currentTenant.features.taxPlan,
        permissions: ["taxPlan"],
      },
      {
        feature: currentTenant.features.machinery,
        permissions: ["brand", "machine"],
      },
      {
        feature: currentTenant.features.infrastructures,
        permissions: ["infrastructure", "infrastructureKind"],
      },
      {
        feature: currentTenant.features.customCropStages,
        permissions: ["cropStage"],
      },
      {
        feature: currentTenant.features.advancedActivities,
        permissions: ["activityCategory", "metric"],
      },
      {
        feature: currentTenant.features.employees,
        permissions: [
          "employee",
          "employeeGroup",
          "position",
          "holiday",
          "leaveAssignment",
          "leaveType",
        ],
      },
      {
        feature: currentTenant.features.expenses,
        permissions: ["expenseItem", "expenseCategory", "financeOrder"],
      },
      {
        feature:
          currentTenant.features.expenses ||
          currentTenant.features.inventoryStock,
        permissions: [
          "counterparty",
          "fiscalYear",
          "paymentMethod",
          "financeInvoice",
        ],
      },
    ];

  const RWDPages = pages
    .filter(
      (p) =>
        p.rwdPermission &&
        (!p.sensitive || includeSensitive || user?.admin) &&
        featurePermissions.every(
          (fp) => fp.feature || !fp.permissions.includes(p.rwdPermission)
        )
    )
    .sort((a, b) =>
      intl.formatMessage(a.title).localeCompare(intl.formatMessage(b.title))
    );

  // TODO: implement condition in modules config
  if (currentTenant.features.infrastructures) {
    RWDPages.push({
      title: { id: "infrastructure", defaultMessage: "infrastructure" },
      module: modules.infrastructure,
    } as any);
  }

  if (currentTenant.features.banks) {
    RWDPages.push({
      title: { id: "banks", defaultMessage: "Banks" },
      module: modules.finance,
    } as any);
  }

  return groupBy(RWDPages, (p) => intl.formatMessage(p.module.title));
}

function useReportKeys() {
  const { currentTenant } = useCurrentUser();

  const featurePermissions: FeaturePermissions<
    (typeof ReportKeys)[number]["key"]
  > = [
    { feature: currentTenant.features.tokens, permissions: ["token"] },
    {
      feature: currentTenant.features.inventoryStock,
      permissions: ["stock", "inventoryMovement"],
    },
    {
      feature: currentTenant.features.accounting,
      permissions: [
        "trialBalance",
        "balanceSheet",
        "incomeStatement",
        "ledger",
        "journal",
      ],
    },
    {
      feature: currentTenant.features.employees,
      permissions: ["payroll", "labor"],
    },
  ];

  return ReportKeys.filter((r) =>
    featurePermissions.every(
      (fp) => fp.feature || !fp.permissions.includes(r.key)
    )
  );
}

function useAdministrativeKeys() {
  const { currentTenant } = useCurrentUser();

  const featurePermissions: FeaturePermissions<
    (typeof AdministrativeKeys)[number]["key"]
  > = [
    {
      feature: currentTenant.features.planning,
      permissions: ["editPlanning"],
    },
    {
      feature: currentTenant.features.testMode,
      permissions: ["resetTestMode", "testMode"],
    },
  ];

  return AdministrativeKeys.filter((r) =>
    featurePermissions.every(
      (fp) => fp.feature || !fp.permissions.includes(r.key)
    )
  );
}

type GroupHeaderProps = {
  groupName: ReactNode;
  permissionKeys: string[][];
  onCheckAll?: () => void;
};

const GroupHeader = ({
  groupName,
  permissionKeys,
  onCheckAll,
}: GroupHeaderProps) => {
  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Form.Item noStyle shouldUpdate={shouldUpdate(...permissionKeys)}>
        {({ getFieldValue, setFields }) => {
          const values = permissionKeys.map((name) => getFieldValue(name));
          const valuesLen = values.length;
          const checkedLen = values.filter(Boolean).length;

          const setCheckAll = (e: CheckboxChangeEvent) => {
            setFields(
              permissionKeys.map((name) => ({
                name: name,
                value: e.target.checked,
              }))
            );

            if (e.target.checked) onCheckAll?.();
          };

          return (
            <Checkbox
              indeterminate={checkedLen > 0 && checkedLen !== valuesLen}
              onChange={setCheckAll}
              checked={checkedLen === valuesLen}
            >
              <strong>{groupName}</strong>
            </Checkbox>
          );
        }}
      </Form.Item>
    </span>
  );
};

const PermissionsCollapse = ({
  collapseKey,
  children,
  groupHeaderProps,
  defaultExpanded,
}: {
  collapseKey: string;
  children: ReactNode;
  groupHeaderProps: GroupHeaderProps;
  defaultExpanded?: boolean;
}) => {
  const [activeKey, setActiveKey] = React.useState<string>();

  return (
    <Collapse
      defaultActiveKey={defaultExpanded ? [collapseKey] : []}
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key as string)}
    >
      <Collapse.Panel
        key={collapseKey}
        header={
          <GroupHeader
            {...groupHeaderProps}
            onCheckAll={() => setActiveKey(collapseKey)}
          />
        }
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

const ReportsCollapse = ({
  name,
  disabled,
  defaultExpanded,
}: {
  name: string;
  disabled?: boolean;
  defaultExpanded?: boolean;
}) => {
  const reportKeys = useReportKeys();

  return (
    <PermissionsCollapse
      collapseKey="reports"
      groupHeaderProps={{
        groupName: <FormattedMessage id="reports" defaultMessage="reports" />,
        permissionKeys: reportKeys.map(({ key }) => [name, "report", key]),
      }}
      defaultExpanded={defaultExpanded}
    >
      <GenericPermissionsInput
        items={reportKeys.map(({ key, title }) => ({
          key: [name, "report", key],
          title,
        }))}
        disabled={disabled}
      />
    </PermissionsCollapse>
  );
};

const RWDCollapse = ({
  name,
  groupName,
  entities,
  defaultExpanded,
  disabled,
}: {
  name: string;
  groupName: string;
  entities: any[];
  defaultExpanded?: boolean;
  disabled?: boolean;
}) => {
  const intl = useIntl();
  const permissionKeys = entities.map((p) => p.rwdPermission!);

  return (
    <PermissionsCollapse
      collapseKey={`${groupName}-panel`}
      groupHeaderProps={{
        groupName,
        permissionKeys: permissionKeys.flatMap((p) =>
          RWDPermissions.map((o) => [name, p, o])
        ),
      }}
      defaultExpanded={defaultExpanded}
    >
      <Space vertical>
        {entities.map(({ rwdPermission, title }, i) => (
          <RWDPermissionInput
            key={`${groupName}-${i}`}
            name={[name, rwdPermission as string]}
            title={intl.formatMessage(title)}
            disabled={disabled}
          />
        ))}
      </Space>
    </PermissionsCollapse>
  );
};

const PermissionsInput = ({
  name,
  disabled,
  defaultExpanded,
}: {
  name: string;
  disabled?: boolean;
  defaultExpanded?: boolean;
}) => {
  const inputs = useRwdInputs();
  const administrativeKeys = useAdministrativeKeys();

  return (
    <Space vertical>
      <PermissionsCollapse
        collapseKey="localities.map"
        groupHeaderProps={{
          groupName: (
            <FormattedMessage id="localities.map" defaultMessage="Farm Map" />
          ),
          permissionKeys: FarmMapKeys.map(({ key }) => [name, "farmMap", key]),
        }}
        defaultExpanded={defaultExpanded}
      >
        <GenericPermissionsInput
          items={FarmMapKeys.map(({ key, title }) => ({
            key: [name, "farmMap", key],
            title,
          }))}
          disabled={disabled}
        />
      </PermissionsCollapse>

      {Object.entries(inputs)
        .sort(([aGroupName], [bGroupName]) =>
          aGroupName.localeCompare(bGroupName)
        )
        .map(([groupName, entities]) => (
          <RWDCollapse
            key={groupName}
            name={name}
            groupName={groupName}
            entities={entities}
            defaultExpanded={defaultExpanded}
            disabled={disabled}
          />
        ))}

      <ReportsCollapse
        name={name}
        disabled={disabled}
        defaultExpanded={defaultExpanded}
      />

      <PermissionsCollapse
        collapseKey="specialPermissions"
        groupHeaderProps={{
          groupName: (
            <FormattedMessage
              id="permissions.special"
              defaultMessage="Special Permissions"
            />
          ),
          permissionKeys: administrativeKeys.map(({ key }) => [
            name,
            "settings",
            key,
          ]),
        }}
        defaultExpanded={defaultExpanded}
      >
        <GenericPermissionsInput
          items={administrativeKeys.map(({ key, title }) => ({
            key: [name, "settings", key],
            title,
          }))}
          disabled={disabled}
        />
      </PermissionsCollapse>
    </Space>
  );
};

const RWDPermissions_COLORS_MAP = {
  read: "blue",
  write: "green",
  delete: "orange",
};

const RWDPermissionsView = ({
  name,
  rwd,
  title,
}: {
  name: string;
  title: ReactNode;
  rwd: RwdPermissionsFragment;
}) => {
  const names = RWDPermissionNames[name] || {};

  return (
    <Row>
      <Col span={12}>{title}</Col>

      {RWDPermissions.map((key) => (
        <Col key={key} span={4} style={{ textAlign: "center" }}>
          {rwd[key] && (
            <Tag color={RWDPermissions_COLORS_MAP[key]}>
              <FormattedMessage
                {...{ id: names[key] || `permissions.${key}` }}
              />
            </Tag>
          )}
        </Col>
      ))}
    </Row>
  );
};

const GenericPermissionsView = ({
  title,
  items,
  permissions,
}: {
  title: ReactNode;
  items: { key: string; title: MessageDescriptor }[];
  permissions: any;
}) => (
  <Row style={{ marginBottom: "8px" }}>
    <Col span={12}>{title}</Col>
    <Col span={4}>
      <List
        size="small"
        style={{ marginTop: "-7px" }}
        dataSource={items}
        split={false}
        renderItem={({ key, title }) => {
          if (!permissions[key]) return;

          return (
            <List.Item style={{ paddingLeft: 0, borderBottom: "none" }}>
              <Tag color="blue">
                <FormattedMessage {...title} />
              </Tag>
            </List.Item>
          );
        }}
      />
    </Col>
  </Row>
);

const View = ({ value }: { value: PermissionsFragment }) => {
  const intl = useIntl();
  const inputs = useRwdInputs({ includeSensitive: true });
  const reportKeys = useReportKeys();
  const administrativeKeys = useAdministrativeKeys();

  const showReports = reportKeys.some(
    ({ key }) => value.report?.[key as keyof PermissionsFragment["report"]]
  );

  const showFarmMap = FarmMapKeys.some(
    ({ key }) => value.farmMap?.[key as keyof PermissionsFragment["farmMap"]]
  );

  const showOther = administrativeKeys.some(
    ({ key }) => value.settings?.[key as keyof PermissionsFragment["settings"]]
  );

  return (
    <>
      {showFarmMap && (
        <GenericPermissionsView
          title={
            <FormattedMessage id="localities.map" defaultMessage="Farm Map" />
          }
          items={FarmMapKeys}
          permissions={value.farmMap}
        />
      )}

      {Object.entries(inputs)
        .sort(([aGroupName], [bGroupName]) =>
          aGroupName.localeCompare(bGroupName)
        )
        .map(([groupName, permissions]) => {
          if (!permissions.length) return null;

          const activePermissions = permissions.filter((p) => {
            const rwd = value[p.rwdPermission!] as RwdPermissionsFragment;

            if (!rwd) return;

            return RWDPermissions.some((key) => rwd[key]);
          });

          if (!activePermissions.length) return null;

          return (
            <Space key={groupName} vertical>
              <strong>{groupName}</strong>

              <Space vertical>
                {activePermissions.map(
                  ({ rwdPermission, title }) =>
                    rwdPermission && (
                      <RWDPermissionsView
                        name={rwdPermission}
                        key={`${groupName}-${rwdPermission}`}
                        title={intl.formatMessage(title)}
                        rwd={value[rwdPermission] as RwdPermissionsFragment}
                      />
                    )
                )}
              </Space>

              <Divider />
            </Space>
          );
        })}

      <Space vertical split={<Divider />}>
        {showReports && (
          <GenericPermissionsView
            title={<FormattedMessage id="reports" />}
            items={reportKeys}
            permissions={value.report}
          />
        )}

        {showOther && (
          <GenericPermissionsView
            title={
              <FormattedMessage
                id="permissions.special"
                defaultMessage="Special Permissions"
              />
            }
            items={administrativeKeys}
            permissions={value.settings}
          />
        )}
      </Space>
    </>
  );
};
PermissionsInput.View = View;

export default PermissionsInput;
